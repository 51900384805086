import * as React from 'react';
import gql from 'graphql-tag';
import qs from 'qs';
import { DataProxy } from 'apollo-cache';
import { Query, Mutation, MutationFn } from 'react-apollo';
import { Redirect } from 'react-router-dom';

import { routes } from 'inkp-routes';
import { hasAb } from 'inkp-user-sdk/user';

import { CURRENT_USER_QUERY } from '../../util/login';
import { updateCurrentUser } from '../../graphql/users';

const FORCE_AB = gql`
  mutation forceAb($experimentName: String!, $variantValue: String!) {
    forceAb(experimentName: $experimentName, variantValue: $variantValue) {
      id
      email
      ab {
        name
        value
      }
    }
  }
`;

export default function(props: { location: any }) {
  const abTests = qs.parse(`${props.location.search}`, { ignoreQueryPrefix: true });
  const experimentName = Object.keys(abTests)[0];
  const variantValue = abTests[experimentName];
  return (
    <Query query={CURRENT_USER_QUERY}>
      {({ data: userData }: any) => (
        <Mutation
          mutation={FORCE_AB}
          variables={{ experimentName, variantValue }}
          update={(cache: DataProxy, { data }: any) => {
            updateCurrentUser(cache, data.forceAb);
          }}
        >
          {(forceAb: MutationFn) => {
            if (!userData || !hasAb(userData.currentUser, experimentName, variantValue)) {
              forceAb();
            }
            return <Redirect to={routes.app.base} />
          }}
        </Mutation>
      )}
    </Query>
  )
}